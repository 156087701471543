<template>
    <div id="nav-container" :class="{ 'has-breadcrumbs': hasBreadcrumbs }">
        <nav id="nav" class="nav">
            <div class="header-nav-left">
                <a :href="logoUrl">
                    <logo />
                </a>
                <slot name="breadcrumbs" />
            </div>
            <p class="header-title">
                <slot />
            </p>
            <div class="menu-icon-wrapper" v-if="!disableDropdownMenu">
                <button class="menu-icon" />
                <div class="sub-menu left">
                    <portal-target name="navigation-menu" slim />
                    <slot name="before-menu-links" />
                    <router-link
                        v-if="!disableMyMaps && $auth.getAuthUser()"
                        :to="{ name: 'user-maps', params: { user: $auth.getAuthUser().id } }"
                        class="sub-menu-option"
                        >My Maps</router-link
                    >
                    <router-link :to="{ name: 'sso-logout' }" class="sub-menu-option">Log out</router-link>
                    <slot name="after-menu-links" />
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import Logo from '@/components/layout/logo';
export default {
    components: {
        Logo,
    },
    props: {
        url: {
            type: String,
            default: null,
        },
        disableMyMaps: {
            type: Boolean,
            default: false,
        },
        disableDropdownMenu: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        logoUrl() {
            return this.url ? this.url : '/';
        },
        hasBreadcrumbs() {
            return !!this.$slots['breadcrumbs'];
        },
    },
};
</script>
