<template>
    <div>
        <slot />
        <r-loading-bounce v-if="loading" />
        <header-nav>Curated Map Users</header-nav>
        <div class="outer users-list-page">
            <div class="map-toolbar">
                <h2>User Management</h2>
                <div class="search-bar-wrapper">
                    <img class="search-icon" src="/images/search-icon.svg" alt="Search icon" />
                    <input type="text" placeholder="Search Map Users" @input="onSearchChange" />
                </div>
                <button class="pill-btn pill-btn-icon" @click="addUser">
                    <span class="pill-btn-text">Add User</span>
                </button>
            </div>
            <div class="users-list">
                <div class="user-list-row user-list-header">
                    <div class="user-list-column">Name</div>
                    <div class="user-list-column">Role</div>
                    <div class="user-list-column">Last Login</div>
                </div>
                <div v-for="user in users" class="user-list-row user-list-details-wrapper" :key="user.id">
                    <router-link
                        :to="{ name: 'user-maps', params: { user: user.id } }"
                        class="user-list-column user-name"
                        >{{ user.first_name }} {{ user.last_name }}</router-link
                    >
                    <router-link
                        :to="{ name: 'user-maps', params: { user: user.id } }"
                        class="user-list-column user-role"
                        >{{ user.role.role }}</router-link
                    >
                    <router-link
                        :to="{ name: 'user-maps', params: { user: user.id } }"
                        class="user-list-column user-last-login"
                        >{{ showLastlogin(user.last_login_at) }}</router-link
                    >
                    <div class="menu-icon-wrapper user-list-column">
                        <button class="menu-icon"></button>
                        <div class="sub-menu">
                            <router-link :to="{ name: 'user-maps', params: { user: user.id } }" class="sub-menu-option"
                                >View Maps</router-link
                            >
                            <div class="sub-menu-option" @click.stop="invite(user)" v-if="!user.last_login_at">
                                Resend Invite
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <pagination
                v-if="meta && !isSearching"
                :total-pages="meta.last_page"
                :total="meta.total"
                :per-page="meta.per_page"
                :current-page="meta.current_page"
                @pageChanged="onPageChange"
            />
        </div>

        <add-user-form v-if="isModalOpen" @close="isModalOpen = false" />
    </div>
</template>

<script>
import { debounce } from 'utils/lodash';
import HeaderNav from 'components/layout/header';
import pagination from 'components/Pagination';
import moment from 'moment';
import User from 'models/User';
import AddUserForm from 'components/users/modal/addform';
import IntercomMixin from '@/mixins/intercom.vue';

export default {
    components: {
        HeaderNav,
        pagination,
        AddUserForm,
    },
    mixins: [IntercomMixin],
    metaInfo: {
        title: 'Curated Map Users',
    },
    created() {
        let page = this.$route.query.page ? this.$route.query.page : 1;
        this.fetchData(page);
    },
    data() {
        return {
            loading: false,
            users: null,
            meta: null,
            isSearching: false,
            tempUsers: null,
            isModalOpen: false,
        };
    },
    methods: {
        showLastlogin(lastLoginAt) {
            return lastLoginAt ? moment(lastLoginAt).fromNow() : 'Unknown';
        },
        onPageChange(page) {
            this.$router.push({ name: 'users', query: { page: page } });
            this.fetchData(page);
        },
        fetchData(page) {
            if (this.loading) {
                return;
            }

            this.loading = true;
            User.all(page)
                .then((response) => {
                    this.users = response.data.data;
                    this.meta = response.data.meta;
                    this.checkPage();
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        checkPage() {
            if (this.meta.current_page > this.meta.last_page) {
                this.onPageChange(this.meta.last_page);
            }
        },
        onSearchChange: debounce(function (input) {
            if (!this.isSearching) {
                this.tempUsers = this.users;
                this.isSearching = true;
            }
            if (input.target.value === '') {
                this.users = this.tempUsers;
                this.isSearching = false;
            } else {
                this.isSearching = true;
                User.search(input.target.value)
                    .then((response) => (this.users = response.data.data))
                    .catch((error) => {
                        return error;
                    });
            }
        }, 200),
        addUser: function () {
            this.isModalOpen = true;
        },
        invite: function (user) {
            User.invite(user.id).then(() => {
                alert('Success. The invite has been sent to ' + user.first_name + '.');
            });
        },
    },
};
</script>
