<template>
    <r-select
        v-model="area"
        :options="areas"
        track-by="id"
        label="name"
        :id="id"
        :name="name"
        empty
        @change="onChange"
    />
</template>

<script>
import { isEmpty } from 'utils/lodash';
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {
        area: {
            type: [String, Number],
        },
        id: {
            type: String,
            default: 'area_id',
        },
        name: {
            type: String,
            default: 'area_id',
        },
    },
    computed: {
        ...mapGetters('areas', ['areas']),
    },
    created() {
        if (isEmpty(this.areas)) {
            this.all();
        }
    },
    methods: {
        ...mapActions('areas', ['all']),
        onChange(value) {
            this.$emit('change', value);
        },
    },
};
</script>
