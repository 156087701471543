<template>
    <r-modal open title="New User" @close="onClose">
        <r-loading-bounce v-if="loading" />
        <form @submit.prevent="onSubmit">
            <label for="firstname">First Name</label>
            <r-input
                v-focus
                id="firstname"
                name="firstname"
                placeholder="Enter First Name"
                v-model="user.first_name"
                :class="{ invalid: checkFormInputs.first_name }"
            />
            <label for="lastname">Last Name</label>
            <r-input
                id="lastname"
                name="lastname"
                placeholder="Enter Last Name"
                v-model="user.last_name"
                :class="{ invalid: checkFormInputs.last_name }"
            />
            <label for="email">Email Address</label>
            <r-input
                type="email"
                id="email"
                name="email"
                placeholder="Enter Email Address"
                v-model="user.email"
                :class="{ invalid: checkFormInputs.email }"
            />

            <div>
                <label for="role_id">Roles</label>
                <user-roles-dropdown :role="user.role_id" @change="(value) => (user.role_id = value)" />
            </div>

            <div v-if="hasAreas">
                <label for="area_id">Area</label>
                <areas-dropdown :role="user.area_id" @change="(value) => (user.area_id = value)" />
            </div>

            <r-button @click="onSubmit">Save</r-button>
        </form>
    </r-modal>
</template>

<script>
import User from 'models/User';

import AreasDropdown from 'components/areas/dropdown';
import UserRolesDropdown from 'components/users/roles/dropdown';
import { mapGetters } from 'vuex';

export default {
    components: {
        AreasDropdown,
        UserRolesDropdown,
    },
    data: () => ({
        loading: false,
        user: {
            first_name: null,
            last_name: null,
            email: null,
            role_id: null,
            area_id: null,
        },
        checkFormInputs: {
            first_name: false,
            last_name: false,
            email: false,
        },
    }),
    computed: {
        ...mapGetters('areas', ['roleHasAreas']),
        hasAreas() {
            return this.roleHasAreas(this.user.role_id);
        },
    },
    methods: {
        onClose() {
            this.$emit('close');
        },
        checkForm() {
            let isValid = true;

            for (let inputField in this.checkFormInputs) {
                if (this.checkFormInputs.hasOwnProperty(inputField)) {
                    if (!this.user[inputField]) {
                        this.checkFormInputs[inputField] = true;

                        isValid = false;
                    } else {
                        this.checkFormInputs[inputField] = false;
                    }
                }
            }

            return isValid;
        },
        onSubmit() {
            if (this.checkForm()) {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                User.store(this.user).then(
                    (response) => {
                        let userId = response.data.data.id;

                        if (this.hasAreas) {
                            User.assignArea(this.user.area_id, userId);
                        }

                        User.invite(userId).then(() => {
                            this.loading = false;
                            this.onClose();
                            alert('An invite email has been sent');
                        });
                    },
                    () => {
                        this.loading = false;
                    }
                );
            }
        },
    },
};
</script>
