<template>
    <div class="pagination-wrapper" v-if="totalPages > 1">
        <div @click="onClickPreviousPage" v-if="!isInFirstPage">Prev</div>

        <div
            v-for="page in pages"
            @click="onClickPage(page.name)"
            :key="page.name"
            :class="{ active: isCurrentPage(page.name) }"
        >
            {{ page.name }}
        </div>

        <div @click="onClickNextPage" type="button" v-if="!isInLastPage">Next</div>
    </div>
</template>

<script>
export default {
    props: {
        maxVisibleButtons: {
            type: Number,
            required: false,
            default: 3,
        },
        totalPages: {
            type: Number,
            required: true,
        },
        total: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
    },
    computed: {
        startPage() {
            // When on the first page
            if (this.currentPage === 1) {
                return 1;
            }
            // When on the last page
            if (this.currentPage === this.totalPages) {
                return Math.max(this.totalPages - this.maxVisibleButtons, this.maxVisibleButtons - this.totalPages);
            }
            // When in between
            return this.currentPage - 1;
        },
        pages() {
            const range = [];

            for (
                let i = this.startPage;
                i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
                i += 1
            ) {
                range.push({
                    name: i,
                    isDisabled: i === this.currentPage,
                });
            }

            return range;
        },
        isInFirstPage() {
            return this.currentPage === 1;
        },
        isInLastPage() {
            return this.currentPage === this.totalPages;
        },
    },
    methods: {
        onClickPreviousPage() {
            this.$emit('pageChanged', this.currentPage - 1);
        },
        onClickPage(page) {
            if (!this.isCurrentPage(page)) {
                this.$emit('pageChanged', page);
            }
        },
        onClickNextPage() {
            this.$emit('pageChanged', this.currentPage + 1);
        },
        isCurrentPage(page) {
            return page === this.currentPage;
        },
    },
};
</script>
